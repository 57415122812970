import React from 'react'
import Layout from '../components/Layout'
import Hero from "../components/Hero"
import { graphql } from 'gatsby'
import { returnTitleSubHeadingsAndImages } from '../utilities/helperFunctions'
import ClientsList from '../components/ClientsList'
import SectionTitleComponent from '../components/SectionTitleComponent'
import PortfolioCards from '../components/PortfolioCards'
import { makeStyles, TextField, MenuItem, Grid, Select } from '@material-ui/core'
import PaginationComponent from '../components/GeneralComponents/PaginationComponent'
import Routes from '../utilities/Routes'
import { contactFormId } from '../utilities/Configurations'

const useStyles = makeStyles(theme => ({
    container: {
        padding: '20px',
        [theme.breakpoints.up('sm')]: {
            padding: '30px 2rem'
        }
    },
    dropdown: {
        padding: '20px 0px',
        display: 'flex', justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            padding: "2rem 0px"
        }
    }
}))

const itemsPerPage = 6

function Portfolio({ data }) {
    const classes = useStyles()
    const pageContent = data.cms.brsPortfolioContent
    const heroData = returnTitleSubHeadingsAndImages(pageContent)
    const SEOData = !!pageContent.Seo ? pageContent.Seo : {}
    const clientsList = data.cms.brsClientsLists.length !== 0 ? data.cms.brsClientsLists[0].clientImages : []
    const projectCategories = !!data.cms.brsProjectCategories ? [{ id: 'all', category: "All Categories" }, ...data.cms.brsProjectCategories] : []
    const projects = !!data.cms.brsProjects ? data.cms.brsProjects : []
    const [projCat, setProjCat] = React.useState('all')
    const [filterProj, setFilterProj] = React.useState(projects)
    const [paginatedProj, setPaginatedProj] = React.useState(projects.slice(0, itemsPerPage))
    const [page, setPage] = React.useState(1)
    const [totalCount, setTotalCount] = React.useState(Math.ceil(projects.length / itemsPerPage))

    const updateCategory = (value) => {

        setProjCat(value)
        let filteredProjects = value === 'all' ? projects : projects.filter(item => {
            let itemCatIds = item.brs_project_categories.map(idValue => idValue.id)
            if (itemCatIds.includes(value)) return true
            else return false
        })
        setFilterProj(filteredProjects)
        setPage(1)
        setTotalCount(Math.ceil(filteredProjects.length / itemsPerPage))
        setPaginatedProj(filteredProjects.slice(0, itemsPerPage))
    }

    const changePage = (page) => {
        const offset = (page - 1) * itemsPerPage
        const slicedList = filterProj.slice(offset, offset + itemsPerPage)
        setPage(page)
        setPaginatedProj(slicedList)
    }
    return (
        <div>
            <Layout pageSeo={SEOData}>
                <Hero
                    title={heroData.title}
                    subHeadings={[{ id: "sub-heading", value: heroData.subHeading }]}
                    coverImageMobile={heroData.coverImageMobile}
                    coverImageWeb={heroData.coverImageWeb}
                    buttonTitle='Get an Estimate'
                    buttonHref={`${Routes.portfolio}/#${contactFormId}`}
                />
                <div className='mainContainer'>
                    <div className={classes.container} id='project-list'>
                        <div className={classes.dropdown}>
                            <Grid container justifyContent='flex-end'>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField variant='outlined' value={projCat} fullWidth={true} onChange={(e) => updateCategory(e.target.value)} margin='dense' select={true}>
                                        {
                                            projectCategories.map(cat => {
                                                return <MenuItem key={cat.id} value={cat.id} >{cat.category}</MenuItem>
                                            })
                                        }
                                    </TextField>
                                </Grid>
                            </Grid>
                        </div>
                        <PortfolioCards
                            cardList={paginatedProj}
                        />
                        <div className={classes.dropdown}>
                            <PaginationComponent
                                page={page} count={totalCount}
                                onPageChange={(event, page) => {
                                    changePage(page)
                                    if (totalCount > 1) {
                                        document.getElementById("project-list").scrollIntoView()
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                {
                    clientsList.length !== 0 &&
                    <SectionTitleComponent
                        sectionId='clients'
                        title='Join our list of clients'>
                        <ClientsList
                            clipList={true}
                            clientList={clientsList}
                        />
                    </SectionTitleComponent>
                }
            </Layout>
        </div>
    )
}

export const query = graphql`
query PortfolioQuery {
    cms {
      brsPortfolioContent {
        Seo {
          SCHEMA_TAG
          SEO_DESCRIPTION
          SEO_TITLE
        }
        coverImageMobile {
          url
          alternativeText
        }
        coverImageWeb {
          alternativeText
          url
        }
        heading
        subHeading
      }
      brsClientsLists {
        id
        clientImages {
          id
          clientImage {
            alternativeText
            url
          }
        }
      }
      brsProjectCategories {
        id
        category
      }
      brsProjects {
        id
        projectLocation
        slug
        clientTitle
        clientLogo {
          url
          alternativeText
        }
        excerpt
        doesHaveDetail
        brs_project_categories {
          id
          category
        }
      }
    }
  }
`

export default Portfolio