import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import ImageComponent from '../GeneralComponents/ImageComponent'
import SecondaryButton from '../GeneralComponents/Buttons/SecondaryButton'
import LocationIcon from '@material-ui/icons/LocationOn'
import RichTextComponent from '../RichTextComponent'
import Routes from '../../utilities/Routes'
import LinkComponent from '../GatsbyComponents/LinkComponent'
import TagComponent from '../GeneralComponents/TagComponent'
const useStyles = makeStyles(theme => ({
    container: {
        boxShadow: "0px 8px 24px rgba(42, 106, 226, 0.1)",
        borderRadius: "12px",
        height: "100%",
        display: 'flex'
    },
    imageStyles: {
        width: '100%',
        height: '200px',
        maxHeight: "250px",
        borderRadius: "12px 12px 0px 0px",
        objectFit: 'contain',
    },
    buttonStyles: {
        minWidth: "180px"
    },
    textContainer: {
        maxHeight: '120px',
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            maxHeight: "140px"
        }
    }
}))

function PortfolioCard({ data }) {
    const { clientTitle, excerpt, projectLocation, doesHaveDetail, clientLogo, brs_project_categories, slug } = data
    const classes = useStyles()
    return (
        <div className={classes.container}>
            <Grid container direction='column'  >
                <Grid item>
                    <div style={{ padding: "1rem" }}>
                        <ImageComponent
                            src={!!clientLogo ? clientLogo.url : ""}
                            alt={!!clientLogo ? clientLogo.alternativeText : ""}
                            className={classes.imageStyles}
                        />
                    </div>
                </Grid>
                <Grid item style={{ flex: 1, display: 'flex' }}>
                    <div style={{ padding: '15px', flex: 1, display: 'flex' }}>
                        <Grid container direction='column' justifyContent='space-between' spacing={2}>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {
                                            (!!doesHaveDetail && !!slug) ?
                                                <LinkComponent path={`${Routes.portfolio}/${slug}`}>
                                                    <Typography variant='h5' color='textPrimary' >{clientTitle}</Typography>
                                                </LinkComponent>
                                                :
                                                <Typography variant='h5' color='textPrimary' >{clientTitle}</Typography>
                                        }
                                    </Grid>
                                    {
                                        !!projectLocation &&
                                        <Grid item xs={12}>
                                            <Grid container spacing={1} alignItems='center'>
                                                <Grid item ><LocationIcon color='primary' /></Grid>
                                                <Grid item><Typography variant='body1' color='textPrimary'>{projectLocation}</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {
                                        !!excerpt &&
                                        <Grid item xs={12}>
                                            <Typography variant='body1' color='textPrimary' style={{ whiteSpace: 'pre-line' }} >{excerpt}</Typography>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <TagComponent array={brs_project_categories.map(item => item.category)} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                !!doesHaveDetail && !!slug &&
                                <Grid item>
                                    <Grid container spacing={1} justifyContent='center'>
                                        <Grid item >
                                            <SecondaryButton href={`${Routes.portfolio}/${slug}`}>
                                                Read More
                                            </SecondaryButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}


export default PortfolioCard